import { ReactComponent as symbol1_stroke } from "./frame1.svg";
import { ReactComponent as symbol1_fill } from "./frame1-2.svg";
import { ReactComponent as symbol2_stroke } from "./frame2.svg";
import { ReactComponent as symbol2_fill } from "./frame2-2.svg";
import { ReactComponent as symbol3_stroke } from "./frame3.svg";
import { ReactComponent as symbol3_fill } from "./frame3-2.svg";
import { ReactComponent as symbol4_stroke } from "./frame4.svg";
import { ReactComponent as symbol4_fill } from "./frame4-2.svg";
import { ReactComponent as symbol5_stroke } from "./frame5.svg";
import { ReactComponent as symbol5_fill } from "./frame5-2.svg";
import { ReactComponent as symbol6_stroke } from "./frame6.svg";
import { ReactComponent as symbol6_fill } from "./frame6-2.svg";
import { ReactComponent as symbol7_stroke } from "./frame7.svg";
import { ReactComponent as symbol7_fill } from "./frame7-2.svg";

export const symbolSvgStroke = [
  symbol1_stroke,
  symbol2_stroke,
  symbol3_stroke,
  symbol4_stroke,
  symbol5_stroke,
  symbol6_stroke,
  symbol7_stroke,
];

export const symbolSvgFill = [
  symbol1_fill,
  symbol2_fill,
  symbol3_fill,
  symbol4_fill,
  symbol5_fill,
  symbol6_fill,
  symbol7_fill,
];

export const symbolSvg = [
  symbol1_fill,
  symbol2_stroke,
  symbol3_stroke,
  symbol4_stroke,
  symbol5_stroke,
  symbol6_stroke,
  symbol7_stroke,
];
