import Buffer from "buffer";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "utils/RTK";
import App from "./App";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;
// @ts-ignore
window.Buffer = window.Buffer || Buffer.Buffer;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
