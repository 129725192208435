import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import { AnimatePresence } from "framer-motion";
import LoadingSpinner from "components/Common/LoadingSpinner";
import PageNotFound from "pages/PageNotFound";
import "assets/scss/main.scss";

const Home = lazy(() => import("pages/Home"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const Deploy = lazy(() => import("pages/Deploy"));
const Tokenlist = lazy(() => import("pages/Tokenlist"));
const TokenInfo = lazy(() => import("pages/TokenInfo"));
const Manage = lazy(() => import("pages/Manage/Manage"));
const Pause = lazy(() => import("pages/Manage/Pause"));
const Burn = lazy(() => import("pages/Manage/Burn"));
const Mint = lazy(() => import("pages/Manage/Mint"));
const Success = lazy(() => import("pages/Manage/Success"));

function App() {
  return (
    <div className="app">
      <AnimatePresence>
        <Router>
          <Suspense
            fallback={
              <div className="spinner__wrapper">
                <LoadingSpinner size="x-large" />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/deploy" element={<Deploy />} />
              <Route path="/tokenlist" element={<Tokenlist />} />
              <Route path="/tokenlist/:tokenAddress" element={<TokenInfo />} />
              <Route path="/tokenlist/:tokenAddress/manage" element={<Manage />} />
              <Route path="/tokenlist/:tokenAddress/manage/pause" element={<Pause />} />
              <Route path="/tokenlist/:tokenAddress/manage/burn" element={<Burn />} />
              <Route path="/tokenlist/:tokenAddress/manage/mint" element={<Mint />} />
              <Route path="/tokenlist/:tokenAddress/manage/success" element={<Success />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </Router>
      </AnimatePresence>
    </div>
  );
}

export default App;
