import React, { useState } from "react";
import { Link } from "react-router-dom";
import { publicFolderURL } from "components/Common/publicFolderURL";

const PageNotFound = () => {
  const [isMouseHover, setIsMouseHover] = useState(false);

  return (
    <section className="PageNotFound">
      <nav className="PageNotFound__nav">
        <Link to="/">
          <img src={publicFolderURL("images/SM_Symbol.svg")} alt="SMSymbol" style={{ width: "4rem", height: "4rem" }} />
        </Link>
      </nav>
      <article className="PageNotFound__contents__wrapper">
        <div className="PageNotFound__img__wrapper">
          <img src={publicFolderURL("images/page/pageNotFound/error_stone-1.png")} alt="error_stone" width="100%" />
        </div>
        <div className="PageNotFound__text__wrapper">
          <h1>Page Not Found</h1>
          <span>404 error</span>
          <div
            onMouseEnter={() => setIsMouseHover(true)}
            onMouseLeave={() => setIsMouseHover(false)}
            className="PageNotFound__button__wrapper"
          >
            <Link to="/">
              <button type="button">Back to Home</button>
            </Link>
            {isMouseHover ? <div className="PageNotFound__button__underline" /> : ""}
          </div>
        </div>
      </article>
    </section>
  );
};

export default PageNotFound;
