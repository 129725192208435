import { symbolSvg, symbolSvgFill, symbolSvgStroke } from "assets/static/symbolSVG";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  network: ``,
  name: "",
  symbol: "",
  business_information: "",
  decimals: 8,
  initial_supply: "",
  description: "",
  url: "",
  pausable: false,
  burnable: false,
  mintable: false,
  max_supply: "",
  inputMax: "",
  isMaxOptionChecked: false,
  icon: "",
  uploadFile: "",
  isUploadedImg: false,
  figure: 0,
  borderImg: "symbolPNG/frame-1-m.png",
  borderColor: "#BEBDC6",
  insideColor: "#FFFFFF",
  symbolSvg,
};

export const deployTokenSlice = createSlice({
  name: "deployToken",
  initialState,
  reducers: {
    setDeployInput: (state, action: PayloadAction<Input>) => {
      const { type, value } = action.payload;
      return { ...state, [type]: value };
    },
    deleteIcon: (state) => {
      return {
        ...state,
        icon: "",
        uploadFile: "",
      };
    },
    deleteMaxSupply: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        const deletedMaxSupplyStates = { ...state };
        delete deletedMaxSupplyStates.max_supply;
        return deletedMaxSupplyStates;
      }
      const addedMaxSupplyStates = { ...state, max_supply: "" };
      return addedMaxSupplyStates;
    },
    setInitialState: (state) => {
      return {
        ...state,
        network: "",
        name: "",
        symbol: "",
        business_information: "",
        decimals: 8,
        initial_supply: "",
        description: "",
        url: "",
        pausable: false,
        burnable: false,
        mintable: false,
        max_supply: "",
        isMaxOptionChecked: false,
        icon: "",
        uploadFile: "",
        isUploadedImg: false,
        figure: 0,
        borderImg: "symbolPNG/frame-1-m.png",
        borderColor: "#BEBDC6",
        insideColor: "#FFFFFF",
      };
    },
    setSvg: (state, action: PayloadAction<number>) => {
      const symbolSvgClone = [...symbolSvgStroke];
      symbolSvgClone[action.payload] = symbolSvgFill[action.payload];
      return { ...state, symbolSvg: [...symbolSvgClone] };
    },
  },
});

export const { setDeployInput, deleteIcon, deleteMaxSupply, setInitialState, setSvg } = deployTokenSlice.actions; //* 액션함수

export default deployTokenSlice.reducer;

interface Input {
  value: string | number | boolean | File;
  type: string;
}
