import React from "react";

interface LoadingSpinnerProps {
  size?: string;
  margin?: number;
  center?: boolean;
}

const LoadingSpinner = ({ size, margin, center }: LoadingSpinnerProps) => {
  return (
    <div
      className={`spinner ${size} ${center ? `center` : ``}`}
      style={{
        margin: ` ${margin}px ${margin}px ${margin}px ${margin}px`,
      }}
    />
  );
};

LoadingSpinner.defaultProps = {
  size: "",
  margin: "",
  center: false,
};

export default LoadingSpinner;
